<template>
  <div>
    <v-form ref="form">
      <v-checkbox
        max-width="250"
        v-model="contractOptions.sendActivationForm"
        label="Lähetä lomakkeita aktivoinnin yhteydessä"
      ></v-checkbox>

      <div v-if="contractOptions.sendActivationForm">
        <v-autocomplete
          v-model="contractOptions.formIds"
          :items="activeForms"
          item-text="name"
          item-value="_id"
          label="Lomakkeet"
          multiple
          small-chips
          deletable-chips
          outlined
          @focus="getActiveForms"
          :search-input.sync="searchInput"
          @change="searchInput = ''"
          :rules="validations.requiredArray"
        ></v-autocomplete>

        <div v-if="currentFormsIncludeNewForms" class="warning rounded pa-1 mb-2">
          Sopimus sisältää jo valitun lomakkeen. Poista kyseinen lomake sopimuksen riviltä toiminnot
          kohdasta ennenkuin yrität liittää sitä uudelleen.
        </div>

        <h4 class="mb-1">Kenelle lomake lähetetään?</h4>

        <v-text-field
          v-model="contractOptions.formReceivers"
          label="Lomakkeen vastaanottajat"
          outlined
          dense
          placeholder="Erota sähköpostit pilkulla ja välilyönnillä"
          :rules="validations.required.concat(validateEmails)"
          hint="Erota sähköpostit pilkulla ja välilyönnillä"
          persistent-hint
        ></v-text-field>

        <h4 class="mb-1">Kuka vastaanottaa lomakkeen viestejä?</h4>

        <v-text-field
          v-model="contractOptions.recipients"
          label="Vastaanottajat"
          outlined
          dense
          placeholder="Erota sähköpostit pilkulla ja välilyönnillä"
          :rules="validateEmails"
          hint="Erota sähköpostit pilkulla ja välilyönnillä"
          persistent-hint
        ></v-text-field>

        <h4 class="mb-1">Viesti lomakkeen lähettämisen oheen</h4>

        <v-textarea
          v-model="contractOptions.customMessage"
          outlined
          dense
          rows="4"
          placeholder="Rivinvaihdon saat painamalla enteriä..."
        ></v-textarea>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import validations from "@/validations";
import { getRentalContractTenantEmails } from "@/utils/rentalContractHelpers";
import mixins from "@/mixins/mixins";

export default {
  mixins: [mixins],

  data() {
    return {
      searchInput: "",
      validations,
      getRentalContractTenantEmails,
    };
  },

  computed: {
    ...mapState("form", ["contractOptions", "activeForms"]),
    ...mapState("contract", ["currentContract"]),

    validateEmails() {
      return [
        (v) =>
          !v ||
          v.split(", ").every((email) => validations.isValidEmail(email)) ||
          "Emailit on väärin muotoiltu",
      ];
    },

    currentFormIds() {
      if (this.currentContract.forms) {
        return this.currentContract.forms.map((el) => {
          if (el.form) return el.form._id;
        });
      } else {
        return [];
      }
    },

    currentFormsIncludeNewForms() {
      return this.contractOptions.formIds.some((value) => this.currentFormIds.includes(value));
    },
  },

  watch: {
    "contractOptions.sendActivationForm"(val) {
      if (!val) {
        this.resetFields();
      }

      if (val) {
        this.setTenantEmailsAsFormReceivers();
      }
    },
  },

  methods: {
    ...mapActions("form", ["getActiveForms"]),

    resetFields() {
      this.contractOptions.sendActivationForm = false;
      this.contractOptions.formIds = [];
      this.contractOptions.recipients = "";
      this.contractOptions.formReceivers = "";
      this.contractOptions.customMessage = "";
    },

    setTenantEmailsAsFormReceivers() {
      const emails = getRentalContractTenantEmails(this.currentContract);
      this.contractOptions.formReceivers = emails.join(", ");
    },

    validate() {
      if (this.currentFormsIncludeNewForms) {
        this.showPopup("Tarkista lomakkeen huomiot!", "error");
        return false;
      } else {
        return this.$refs.form.validate();
      }
    },
  },
};
</script>

<style></style>

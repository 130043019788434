<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Sopimukset</span>
    </div>

    <v-card class="mt-2">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-btn
          v-if="isAuthorized('contract', 'create')"
          color="info"
          class="mb-2 mr-1 ml-auto"
          @click="createContract"
        >
          Uusi vuokrasopimus
        </v-btn>
        <v-btn
          v-if="isAuthorized('contract', 'create')"
          class="info mb-2 mr-1 ml-auto"
          @click="$router.push(`/rental-contract/create-old`)"
        >
          Luo voimassa oleva vuokrasopimus
        </v-btn>
      </v-card-title>

      <v-container>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Nro, vuokralainen, vuokranantaja, kohde, tyyppi, tila..."
              single-line
              hide-details
              solo
              @keyup.enter="
                getDataFromApi();
                showInactiveContracts = false;
              "
              @click:append="
                getDataFromApi();
                showInactiveContracts = false;
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-checkbox
              v-model="showInactiveContracts"
              dense
              label="Näytä vain irtisanotut ja päättyneet sopimukset"
              @change="showInactiveContracts ? (search = '') : ''"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>

      <!-- TABLE -->
      <v-data-table
        :headers="headers"
        :items="rentalContracts"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!--  Overview link -->
        <template #[`item.contractNumber`]="{ item }">
          <router-link style="text-decoration: none" :to="`/rental-contract/overview/${item._id}`">
            <span class="font-weight-bold">
              {{ item.contractNumber }}
            </span>
          </router-link>
        </template>

        <!--  Tenants -->
        <template #[`item.tenant`]="{ item }">
          <router-link
            style="text-decoration: none; margin-right: 5px"
            :to="`/tenant/overview/${item.tenant.tenantId._id}`"
          >
            <span :class="{ 'warning--text': item.tenant.revokedDate }">{{
              item.tenant.name
            }}</span>
          </router-link>

          <v-icon
            size="15"
            style="margin-bottom: 3px"
            @click="openTenantInfoDialog(item.tenant.tenantId)"
            >mdi-eye-outline</v-icon
          >

          <div v-for="(tenant, idx) in item.otherTenants" :key="'a' + idx">
            <router-link
              style="text-decoration: none"
              :to="`/tenant/overview/${tenant.tenantId._id}`"
            >
              <span :class="{ 'warning--text': tenant.revokedDate }">{{ tenant.name }}</span>
            </router-link>
            <v-icon
              size="15"
              style="margin-bottom: 3px; margin-left: 3px"
              @click="openTenantInfoDialog(tenant.tenantId)"
              >mdi-eye-outline</v-icon
            >
          </div>

          <div>
            <v-icon
              v-if="hasContractEmails(item, 'tenant')"
              @click="sendMail(item, 'tenant')"
              size="15"
              >mdi-email-send-outline</v-icon
            >
          </div>
        </template>

        <!--  Landlord -->
        <template #[`item.landlords`]="{ item }">
          <div v-for="(landlord, idx) in item.landlords" :key="'a' + idx">
            <router-link
              v-if="landlord.onModel == 'Partner'"
              style="text-decoration: none"
              :to="`/partner/overview/${landlord.landlordId._id}`"
              >{{ landlord.name }}</router-link
            >
            <span v-else>{{ landlord.name }}</span>
          </div>

          <div>
            <v-icon
              v-if="hasContractEmails(item, 'partner')"
              small
              @click="sendMail(item, 'partner')"
              >mdi-email-send-outline</v-icon
            >
          </div>
        </template>

        <!--  Apartment -->
        <template #[`item.apartment`]="{ item }">
          <router-link
            style="text-decoration: none"
            :to="`/apartment/overview/${item.apartment.id._id}`"
          >
            <span>{{ item.apartment.id.address }}</span>
            <span v-if="item.apartment.id.apartmentNumber"
              >, {{ item.apartment.id.apartmentNumber }}
            </span>
            <br />
          </router-link>

          <div v-for="(room, idx) in getRentedRooms(item.apartment)" :key="idx + 'a'">
            <p class="draft--text" style="margin-bottom: -2px !important">{{ room.name }}</p>
          </div>
        </template>

        <!--  StartDate -->
        <template #[`item.lease.startDate`]="{ item }">
          <span>{{ formatDate(item.lease.startDate) }}</span>
        </template>

        <!--  EndDate -->
        <template #[`item.lease.endDate`]="{ item }">
          <span>{{ formatDate(item.lease.endDate) }}</span>
        </template>

        <!--  RevokedDate -->
        <template #[`item.revokedDate`]="{ item }">
          <span>{{ formatDate(item.revokedDate) }}</span>
        </template>

        <!-- BaseInvoice -->
        <template #[`item.baseInvoice`]="{ item }">
          <div v-html="formatBaseInvoice(item.baseInvoice)"></div>
        </template>

        <!-- Item state -->
        <template #[`item.state`]="{ item }">
          <div class="font-weight-bold d-flex align-center text-no-wrap">
            <div :class="getContractStateColor(item.state)">
              <span> {{ getContractState(item.state) }} </span>
            </div>
          </div>
        </template>

        <!-- Actions -->

        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container" v-if="!item.itemLoading">
            <!-- Menu -->
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined small icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  v-if="isAuthorized('contract', 'create') && !item.revoked && !item.signed"
                  @click="
                    setCurrentContract(item);
                    showSignDialog = true;
                  "
                >
                  <v-list-item-title>Aktivoi sopimus</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="
                    isAuthorized('contract', 'create') &&
                    item.signed &&
                    item.contractType != 'reservation'
                  "
                  @click="$router.push(`/invoice/create/${item._id}`)"
                >
                  <v-list-item-title>Laskuta</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="
                    isAuthorized('contract', 'edit') && item.contractType == 'new' && !item.signed
                  "
                  @click="$router.push(`/rental-contract/edit/` + item._id).catch(() => {})"
                >
                  <v-list-item-title>Muokkaa sopimusta</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="
                    isAuthorized('contract', 'edit') &&
                    item.contractType == 'old' &&
                    item.state != 'Päättynyt' &&
                    item.state != 'Irtisanottu'
                  "
                  @click="$router.push(`/rental-contract/edit-old/` + item._id).catch(() => {})"
                >
                  <v-list-item-title>Muokkaa sopimusta</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="
                    isAuthorized('contract', 'revoke') &&
                    item.signed &&
                    !item.revoked &&
                    item.active
                  "
                  @click.stop="
                    refreshRevoked = false;
                    refreshType = 'newRevokedDate';
                    revokeDialog = true;
                    selectedItem = { ...item };
                  "
                >
                  <v-list-item-title>Irtisano sopimus</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="
                    isAuthorized('contract', 'edit') &&
                    item.signed &&
                    !item.revoked &&
                    item.active &&
                    item.otherTenants.length > 0
                  "
                  @click="
                    setCurrentContract(item);
                    editRentalContractTenantsDialog = true;
                  "
                >
                  <v-list-item-title>Muokkaa vuokralaisia</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="
                    isAuthorized('contract', 'edit') &&
                    item.signed &&
                    item.contractType != 'reservation' &&
                    (item.state == 'Irtisanottu' ||
                      (item.state == 'Päättynyt' &&
                        item.lease.leaseType == 'Toistaiseksi voimassa oleva'))
                  "
                  @click.stop="
                    refreshRevoked = true;
                    revokeDialog = true;
                    selectedItem = { ...item };
                  "
                >
                  <v-list-item-title>Muuta irtisanomispäivää</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="isAuthorized('contract', 'read') && item.contractType == 'new'"
                  @click="
                    showDownloadLoader = true;
                    downloadPdf(item);
                  "
                >
                  <v-list-item-title>Lataa vuokrasopimus</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="isAuthorized('contract', 'delete') && !item.signed"
                  @click="deleteItem(item)"
                >
                  <v-list-item-title>Poista</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-icon
              v-if="isAuthorized('contract', 'create') && item.contractType == 'new'"
              :color="getSignatureColor(item)"
              title="Allekirjoitus"
              small
              @click="
                showSelectSignatureDialog = true;
                setToSignatureState(item);
              "
              >mdi-draw</v-icon
            >

            <v-icon
              v-if="isAuthorized('otherContract', 'create')"
              :class="{ 'info--text': hasItems(item.referenceContracts) }"
              title="Liitesopimukset"
              @click="
                setRefRentalContract(item);
                showReferenceContractDialog = true;
              "
              small
            >
              mdi-file-document-multiple-outline
            </v-icon>

            <v-icon
              v-if="isAuthorized('form', 'edit')"
              title="Vuokrasopimuksen lomakkeet"
              small
              :class="{
                hasForms: getFormStatus(item).hasForms && !getFormStatus(item).hasActiveForms,
                hasActiveForms: getFormStatus(item).hasActiveForms,
              }"
              @click="attachFormToContract(item)"
              >mdi-form-select</v-icon
            >

            <v-icon
              v-if="
                isAuthorized('contract', 'create') && item.depositPaid.paid && !item.depositReleased
              "
              title="Merkitse vakuus vapautetuksi"
              small
              @click="releaseDepositOLD(item)"
              >mdi-lock-open</v-icon
            >

            <v-icon
              v-if="isAuthorized('contract', 'read')"
              :class="{ 'info--text': hasItems(item.files) }"
              v-model="showFileDialog"
              title="Liitä dokumentteja"
              small
              @click="
                contractId = item._id;
                showFileDialog = true;
              "
              >mdi-paperclip</v-icon
            >

            <v-icon
              v-if="isAuthorized('image', 'contractPhoto') && item.contractType == 'new'"
              :class="{ 'info--text': hasItems(item.images) }"
              v-model="showImageDialog"
              title="Liitä valokuvia"
              small
              @click="
                contractItem = item;
                setImages(item.images);
                showImageDialog = true;
              "
              >mdi-camera</v-icon
            >
          </div>

          <v-row v-if="item.itemLoading">
            <v-col>
              <small>Ladataan sopimusta...</small>
              <v-progress-linear color="info" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
          </v-row>
        </template>

        <template #no-data>
          <h2>Ei vuokrasopimuksia</h2>
        </template>
      </v-data-table>

      <!--  Revoke contract -->
      <revoke-contract-dialog
        v-model="revokeDialog"
        :selectedItem="selectedItem"
        :refreshRevoked="refreshRevoked"
        :refreshType="refreshType"
      ></revoke-contract-dialog>
    </v-card>

    <!-- showDepositDialog -->
    <deposit-dialog
      v-model="showDepositDialog"
      :contractNum="selectedContract.contractNumber"
      :depositsArr="selectedContract.deposits"
      :contractId="selectedContract._id"
      @adddeposit="addDeposit($event)"
      @deletedeposit="deleteDeposit($event)"
    >
    </deposit-dialog>

    <!-- Select signature -->
    <select-signature v-model="showSelectSignatureDialog"></select-signature>

    <!-- File dialog -->
    <File
      v-model="showFileDialog"
      :document-id="contractId"
      title="Lataa vuokrasopimuksen liite"
      bucket="vuokranet-sopimukset"
      document="contract"
      model="rental-contract"
      @sendnewfiles="replaceFilesArray"
    ></File>

    <!--  Image dialog -->
    <v-dialog v-model="showImageDialog" max-width="800">
      <v-card>
        <v-card-title>Vuokrasopimus nro. {{ contractItem.contractNumber }}</v-card-title>
        <v-container>
          <!-- Images -->
          <Images
            bucket="vuokranet-vuokrasopimuskuvat"
            model="rental-contract"
            align="center"
            lg="7"
            md="10"
            sm="10"
            :contractId="contractItem._id"
            :previewImages="false"
          ></Images>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="showImageDialog = false"> Poistu </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--  Sign dialog -->
    <sign-dialog v-model="showSignDialog" @signcontract="signContract"></sign-dialog>

    <!-- Attach form -->
    <attach-form-dialog
      v-model="attachForm"
      title="Liitä lomake sopimukseen"
      attachTo="rentalContract"
    ></attach-form-dialog>

    <!-- Add reference contract-->
    <reference-contract
      v-model="showReferenceContractDialog"
      modelType="otherContract"
    ></reference-contract>

    <!-- Download loader -->
    <download-loader
      v-model="showDownloadLoader"
      :loading="downloading"
      text="Odota, ladataan vuokrasopimusta..."
    ></download-loader>

    <!-- Tenant info dialog -->
    <tenant-info-dialog v-model="showTenantInfoDialog" sm="12" md="6" lg="6" />

    <!-- Tenant info dialog -->
    <edit-rental-contract-tenants v-model="editRentalContractTenantsDialog" />
  </div>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import { apiAgent } from "../../services/apiAgent";
import mixins from "../../mixins/mixins";
import DepositDialog from "../../components/RentalContract/DepositDialog";
import SignDialog from "../../components/RentalContract/SignDialog";
import SelectSignature from "../../components/Signature/SelectSignature";
import ReferenceContract from "../../components/RentalContract/ReferenceContract.vue";
import RevokeContractDialog from "../../components/RentalContract/RevokeContractDialog.vue";
import Images from "../../components/Image/Images.vue";
import File from "../../components/File";
import DownloadLoader from "../../components/DownloadLoader.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import globalValues from "@/configs/globalValues";
import { getFormStatuses } from "@/utils/formHelpers";
import AttachFormDialog from "@/components/Form/AttachFormDialog.vue";
import TenantInfoDialog from "@/components/Tenant/TenantInfoDialog";
import EditRentalContractTenants from "@/components/RentalContract/EditRentalContractTenants";

export default {
  title: "Vuokrasopimukset",

  components: {
    DepositDialog,
    SelectSignature,
    File,
    Images,
    SignDialog,
    DownloadLoader,
    ReferenceContract,
    RevokeContractDialog,
    AttachFormDialog,
    TenantInfoDialog,
    EditRentalContractTenants,
  },

  mixins: [mixins],

  data: () => ({
    options: {},
    dialog: false,
    attachForm: false,
    search: "",
    downloading: false,
    showDownloadLoader: false,
    showSignDialog: false,
    showTenantInfoDialog: false,
    showReferenceContractDialog: false,
    refreshType: "newRevokedDate",
    loading: true,
    itemLoading: false,
    searching: false,
    showAddAdditionalTenants: false,
    showInactiveContracts: false,
    showDepositDialog: false,
    showFileDialog: false,
    showImageDialog: false,
    showSelectSignatureDialog: false,
    selectedContract: {},
    menu: false,
    dateFormatted: "",
    date: "",
    revokeDialog: false,
    refreshRevoked: false,
    editRentalContractTenantsDialog: false,
    selectedItem: {},
    contractId: "",
    user: {},
    deposit: {},
    contractItem: {},
    allHeaders: [
      { text: "Nro.", value: "contractNumber", show: [1, 2] },
      { text: "Vuokralainen", value: "tenant", show: [1, 2] },
      { text: "Vuokranantaja", value: "landlords", show: [1, 2] },
      { text: "Kohde", value: "apartment", show: [1, 2] },
      {
        text: "Tyyppi",
        value: "lease.leaseType",
        show: [1, 2],
      },
      { text: "Alkaa", value: "lease.startDate", show: [1, 2] },
      { text: "Päättyy", value: "lease.endDate", show: [1, 2] },
      { text: "Toistuvaislasku", value: "baseInvoice", show: [1, 2] },
      { text: "Tila", value: "state", show: [1, 2] },
      { text: "Päättymispäivä", value: "revokedDate", show: [2] },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
        show: [1, 2],
      },
    ],
    globalValues,
  }),

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("contract", ["totalLength", "rentalContracts"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
    ...mapState("form", ["contractOptions"]),

    headers() {
      return this.allHeaders.filter((el) => {
        if (this.searching) {
          return el;
        } else if (this.showInactiveContracts) {
          return el.show.includes(2);
        } else {
          return el.show.includes(1);
        }
      });
    },
  },

  watch: {
    showInactiveContracts() {
      this.getDataFromApi();
    },

    options: {
      deep: true,
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
    },

    totalLength() {
      this.options.page = 1;
    },

    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  methods: {
    ...mapActions("contract", ["getAllContracts", "deleteContract"]),
    ...mapMutations("contract", ["replaceContract", "setCurrentContract", "setRefRentalContract"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapMutations("signature", ["setSignatureItem", "setContractType"]),
    ...mapMutations("image", ["setImages"]),
    ...mapMutations("pdfViewer", ["openViewer"]),
    ...mapMutations("form", ["setAttachment"]),
    ...mapMutations("tenant", ["setCurrentTenant"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      const search = this.search.trim().toLowerCase();

      // set searching to true or false
      this.searching = search || search != "" ? true : false;

      let url;

      if (search) {
        url = `/api/v1/rental-contract/getAll?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;
      } else {
        url = !this.showInactiveContracts
          ? `/api/v1/rental-contract/getAll?state=all&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`
          : `/api/v1/rental-contract/getAll?state=old&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;
      }

      await this.getAllContracts(url);

      this.loading = false;
    },

    formatBaseInvoice(invoice) {
      if (invoice && invoice.invoiceNumber) {
        return `<span class="font-weight-bold success--text">${invoice.invoiceNumber}</span>`;
      } else if (invoice && !invoice.invoiceNumber) {
        return `<span class="font-weight-bold error--text">Luotu, ei lähetetty</span>`;
      } else {
        return "";
      }
    },

    openTenantInfoDialog(item) {
      this.setCurrentTenant(item);
      this.showTenantInfoDialog = true;
    },

    attachFormToContract(item) {
      this.setAttachment({ field: "rentalContract", val: { ...item } });
      this.attachForm = true;
    },

    getFormStatus(item) {
      return getFormStatuses(item);
    },

    replaceFilesArray(item) {
      const index = this.rentalContracts.findIndex((obj) => obj._id === item._id);
      if (index !== -1) {
        this.rentalContracts[index].files = item.files;
      }
    },

    async signContract(item) {
      try {
        const res = await axiosMethods.update("/api/v1/rental-contract/sign-contract/" + item._id, {
          form: this.contractOptions,
        });
        this.replaceContract(res.data.contract);
        this.showSignDialog = false;

        this.showPopup("Sopimus aktivoitu", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    setToSignatureState(item) {
      this.setSignatureItem(item);
      this.setContractType("rentalContract");
    },

    async createContract() {
      try {
        // If no models, dont let user enter the page
        let res = await axiosMethods.get("/api/v1/contract-model/getAll?search=rentalContract");

        if (res.data.data.length > 0) this.$router.push("/rental-contract/create");
        else
          this.showPopup(
            "Sinun pitää luoda vähintään yksi sopimuspohja, jotta voit luoda vuokrasopimuksen. Luo sopimuspohja sopimuspohjat kohdasta.",
            "error"
          );
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    editItem(item) {
      this.$router.push("/rental-contract/edit/" + item._id);
    },

    async addDeposit(deposit) {
      try {
        const res = await axiosMethods.update(
          "/api/v1/rental-contract/receiveDeposit/" + this.selectedContract._id,
          deposit
        );

        this.selectedContract.deposits = res.data.contract.deposits;

        this.showPopup("Vakuus lisätty", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async deleteDeposit(item) {
      try {
        const res = await axiosMethods.update(
          "/api/v1/rental-contract/deleteDeposit/" + this.selectedContract._id,
          item
        );

        this.selectedContract.deposits = res.data.contract.deposits;

        this.showPopup("Vakuus poistettu", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    // Deprecated!!!
    async releaseDepositOLD(item) {
      try {
        if (
          confirm(`Asetetaanko vakuus palautetuksi vuokrasopimukselle nro. ${item.contractNumber}?`)
        ) {
          await axiosMethods.update("/api/v1/rental-contract/releaseDepositOLD/" + item._id);

          this.showPopup("Vakuus merkattu palautetuksi", "success");
          this.$router.go();
        }
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async deleteItem(item) {
      if (
        confirm("Haluatko varmasti poistaa vuokralaisen " + item.tenant.name + " vuokrasopimuksen?")
      ) {
        this.deleteContract(item._id);
      }
    },

    async downloadPdf(item) {
      try {
        this.downloading = true;

        const res = await apiAgent({
          method: "GET",
          url: `/api/v1/rental-contract/downloadPdf/${item._id}`,
          responseType: "arraybuffer",
        });

        const blob = new Blob([res.data], { type: "application/pdf" });
        this.downloading = false;

        this.openViewer({
          data: blob,
          name: `Vuokrasopimus_${item.contractNumber}.pdf`,
        });
      } catch (err) {
        this.downloading = false;
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },
  },
};
</script>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}

.hasForms {
  color: var(--v-info-base);
}

.hasActiveForms {
  color: var(--v-success-base);
}
</style>
